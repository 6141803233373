import { NavigationExtras, Router } from "@angular/router";
import { PreviousRouteState } from "../interfaces/previous-route-state.interface";
import { previousRoutesStateCheck } from "../interfaces/previous-routes-state.interface";

export class PreviousRoute implements PreviousRouteState {

  public previousRoute: string;

  private navigationExtras: NavigationExtras;

  constructor(private router: Router, private context: string) {
    this.navigationExtras = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras : {};
    this.previousRoute = this.getPreviousRoute();
  }

  public dispatchStoreUpdate(dispatch: (updatedStore: PreviousRouteState & { [name: string ]: any }) => void) {
    dispatch({ previousRoute: this.previousRoute });
  }

  public contextPreviousRouteExistsInRouter(): boolean {
    const { state } = this.navigationExtras;
    if(previousRoutesStateCheck(state) && state.previousRoutes[this.context]) {
      return true;
    }
    else {
      return false;
    }
  }

  private getPreviousRoute(): string {
    if(this.contextPreviousRouteExistsInRouter()) {
      const { state } = this.navigationExtras;
      return state.previousRoutes[this.context];
    }
    else if(this.router.routerState.snapshot.url.split('#')[0]) {
      return this.router.routerState.snapshot.url.split('#')[0];
    }
    else if(document.referrer) {
      const referrerUrl = new URL(document.referrer);
      return referrerUrl.pathname + referrerUrl.search;
    }
    else {
      return '/';
    }
  }
}