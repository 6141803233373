import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber, CountryCode } from 'libphonenumber-js/mobile';

export function phoneNumberValidation(phoneNumber:string, countryCode:CountryCode) : ValidationErrors | null {
  if (!phoneNumber) {
    return null;
  }
  if (isValidPhoneNumber(phoneNumber, countryCode)) {
    return null;
  }
  return { 'notValidPhoneNumber': true }
}

export function phoneNumberValidator(countryCode: CountryCode): ValidatorFn {
  return (control: AbstractControl) => phoneNumberValidation(control.value, countryCode);
}