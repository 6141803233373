export const lmwBaseRoutes = {
  lazyLoadedPath: '',
  lmwHomeStatePath: '',
  lmwHomeStateTitle: 'Learn My Way | Develop Your Digital Skills',
  browseSubjectsStatePath: 'explore-the-subjects',
  browseSubjectsTitle: 'Explore the subjects | Learn My Way',
  browseSubjectsPageTitle: 'Explore the subjects',
  browseTopicsTitle: 'Topics | Learn My Way',
  reportAnIssueStatePath: 'report-an-issue',
  reportAnIssueTitle: 'Report an issue | Learn My Way',
  caseStudyStatePath: 'case-study',
  caseStudyTitle: 'Why Learn My Way? | Learn My Way',
  myLearningHomeStatePath: 'my-learning',
  myLearningHomeTitle: 'My learning | Learn My Way',
  helpStatePath: 'help',
  helpTitle: 'Help | Learn My Way',
  topicLandingTitle: 'Topic introduction | Learn My Way',
  termsAndConditionsStatePath: 'terms-and-conditions',
  termsAndConditionsTitle: 'Terms and conditions | Learn My Way',
  accessibilityStatePath: 'accessibility',
  accessibilityTitle: 'Accessibility statement | Learn My Way',
  cookiesStatePath: 'cookies',
  cookiesTitle: 'Cookies policy | Learn My Way',
  privacyStatePath: 'privacy',
  privacyTitle: 'Privacy policy | Learn My Way',
  homeLinkText: 'Home'
};
