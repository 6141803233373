import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { Help as HelpInterface } from "../interfaces/help.interface";
import { fromEvent, Observable, Subscription } from "rxjs";

@Component({
  template: ''
})
export abstract class Help implements HelpInterface, OnDestroy {

  @Input() title;
  @Input() message;
  @Input() helpPopupPosition: "top" | "bottom" = "bottom";
  @Input() helpPopupCalledFrom?: string;

  public abstract removeHelpMessage;

  private readonly noMatrix: string = "none";

  protected _helpBox: ElementRef;
  protected _elementAttachedTo: ElementRef;

  private windowResize: Subscription;

  @ViewChild('helpBox', { static: false }) set helpBox(helpBoxContent: ElementRef) {
    this._helpBox = helpBoxContent;
    if(helpBoxContent) {
      helpBoxContent.nativeElement.focus();
    }
    if(helpBoxContent && this._elementAttachedTo) {
      this.createWindowResize();
      this.helpBoxPositioning();
    }
  }

  get helpBox() {
    return this._helpBox;
  }

  @Input() set elementAttachedTo(elementAttachedTo: ElementRef) {
    this._elementAttachedTo = elementAttachedTo;
    if(this._elementAttachedTo && this.helpBox) {
      this.createWindowResize();
      this.helpBoxPositioning();
    }
  }

  protected helpBoxPositioning() {
    this.resetHelpBoxTransform();
    const yDifference = this.getYAxisDifference();
    const xDifference = this.getXAxisDifference();
    this.addHelpBoxTransform(yDifference, xDifference);
  }

  protected destroyWindowResize() {
    if(this.windowResize) {
      this.windowResize.unsubscribe();
    }
  }

  private createWindowResize() {
    this.destroyWindowResize();
    this.windowResize = fromEvent(window, 'resize').subscribe(() => {
      if(this.helpBox) {
        this.helpBoxPositioning();
      }
    })
  }

  private getYAxisDifference(): number {
    const helpBoxClientRect = this.helpBox.nativeElement.getBoundingClientRect();
    const elementAttachedToClientRect = this._elementAttachedTo.nativeElement.getBoundingClientRect();
    const difference = (
      helpBoxClientRect[this.helpPopupPosition === "top" ? 'bottom' : 'top']
      - elementAttachedToClientRect[this.helpPopupPosition === "top" ? 'top' : 'bottom']
    );
    const pointerDifference = this.helpPopupPosition === "top" ? -5 : 5;
    return -difference + pointerDifference;
  }

  private addHelpBoxTransform(yDifference: number, xDifference: number) {
    const matrix = this.getHelpBoxCurrentMatrix();
    if(matrix !== this.noMatrix) {
      this.helpBox.nativeElement.style.transform = this.createNewMatrix(matrix, yDifference, xDifference);
    }
    else {
      this.helpBox.nativeElement.style.transform = "translate(" + (xDifference) + "px, " + (yDifference) + "px)";
    }
  }

  private resetHelpBoxTransform() {
    const matrix = this.getHelpBoxCurrentMatrix();
    if(matrix !== this.noMatrix) {
      this.helpBox.nativeElement.style.transform = this.createNewMatrix(matrix, 0, 0);
    }
    else {
      this.helpBox.nativeElement.style.transform = "translate(" + (0) + "px, " + (0) + "px)";
    }
  }

  private createNewMatrix(matrix: string, yDifference: number, xDifference: number): string {
    const noOpeningBracket = matrix.replace('matrix(', '');
    const noBrackets = noOpeningBracket.replace(')', '');
    const matrixList = noBrackets.split(',');
    return `matrix(
      ${matrixList[0]},
      ${matrixList[1]},
      ${matrixList[2]},
      ${matrixList[3]},
      ${Math.floor(xDifference)},
      ${Math.floor(yDifference)})`;
  }

  private getXAxisDifference(): number {
    const helpBoxClientRect = this.helpBox.nativeElement.getBoundingClientRect();
    const elementAttachedToClientRect = this._elementAttachedTo.nativeElement.getBoundingClientRect();
    const difference = (helpBoxClientRect['left'] - elementAttachedToClientRect['left']);
    return -difference;
  }

  private getHelpBoxCurrentMatrix(): string {
    return window.getComputedStyle(this.helpBox.nativeElement).transform;
  }

  ngOnDestroy(): void {
    this.destroyWindowResize();
  }
}