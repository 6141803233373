<div class="grid-container grid-container--skip-to-content show-for-xlarge" tabindex="-1" #contentSkip>
   <a [href]="skipToContentLink+'#content'" class="button button--icon-on-right button--skip-to-content">
    <span>Skip to main content</span>
    <svg class="icon icon--arrow-down" viewBox="0 0 24 24" style="width: 20px; height: 20px">
      <use xlink:href="#icon-arrow-down" href="#icon-arrow-down"></use>
    </svg>
   </a>
</div>
<router-outlet></router-outlet>

<error-global-error-popup
  *ngIf="cms"
  [title]="cms?.data.attributes.title"
  [description]="cms?.data.attributes.description"
  [isOpen]="errorPopupIsOpen$ | async"
  (closePopup)="closeErrorPopup()"
></error-global-error-popup>
