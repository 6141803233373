

<main class="unit-player__content" style="margin-top:0">
    <div class="grid-container">
        <div class="grid-x">
            <div class="cell">
                <h1>Icons pulled from a sprite for Learn My Way</h1>
                <h2>
                    Unit Player icons
                </h2>
                <ul>
                    <li>
                        Exit x <br>
                        <svg class="icon icon--exit" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-exit" href="#icon-exit"></use>
                        </svg>
                    </li>
                    <li>
                        Cog <br>
                        <svg class="icon icon--cog" viewBox="0 0 34 34" style="width: 34px; height: 34px">
                            <use xlink:href="#icon-cog" href="#icon-cog"></use>
                        </svg>
                    </li>
                    <li>
                        Help (this doesn't have a circle around it as it is used differently in different places)<br>
                        <svg class="icon icon--help" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-help" href="#icon-help"></use>
                        </svg>
                    </li>
                    <li>
                        Audio on and off <br>
                        <svg class="icon icon--audio-muted" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-audio-muted" href="#icon-audio-muted"></use>
                        </svg><br>
                        <svg class="icon icon--audio-on" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-audio-on" href="#icon-audio-on"></use>
                        </svg>
                    </li>
                    <li>
                        T for text sizing (will be decreased/enlarged as necessary through CSS)<br>
                        <svg class="icon icon--text-size" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-text-size" href="#icon-text-size"></use>
                        </svg>
                    </li>
                    <li>
                        Info circle (again differing sizes dealt with through CSS - this has a circle around it as it's only ever displayed like this)<br>
                        <svg class="icon icon--info-circle" viewBox="0 0 30 30" style="width: 30px; height: 30px">
                            <use xlink:href="#icon-info-circle" href="#icon-info-circle"></use>
                        </svg>
                    </li>
                    <li>
                        Contact someone (two speech bubbles)<br>
                        <svg class="icon icon--speech-bubbles" viewBox="0 0 30 30" style="width: 30px; height: 30px">
                            <use xlink:href="#icon-speech-bubbles" href="#icon-speech-bubbles"></use>
                        </svg>
                    </li>
                    <li>
                        Left, right and down arrows<br>
                        <svg class="icon icon--arrow-left" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-arrow-left" href="#icon-arrow-left"></use>
                        </svg>
                        <svg class="icon icon--arrow-right" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-arrow-right" href="#icon-arrow-right"></use>
                        </svg>
                        <svg class="icon icon--arrow-down" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-arrow-down" href="#icon-arrow-down"></use>
                        </svg>
                    </li>
                    <li>
                        Restart<br>
                        <svg class="icon icon--restart" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-restart" href="#icon-restart"></use>
                        </svg>
                    </li>
                    <li>
                        Checkmark<br>
                        <svg class="icon icon--checkmark" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-checkmark" href="#icon-checkmark"></use>
                        </svg>
                    </li>
                </ul>
                <h2>
                    Registration icons
                </h2>
                <ul>
                    <li>
                        Show/hide password
                        <svg class="icon icon--show-password" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-show-password" href="#icon-show-password"></use>
                        </svg>
                        <br>
                        <svg class="icon icon--hide-password" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-hide-password" href="#icon-hide-password"></use>
                        </svg>
                    </li>
                    <li>
                        User
                        <svg class="icon icon--user" viewBox="0 0 30 30" style="width: 30px; height: 30px">
                            <use xlink:href="#icon-user" href="#icon-user"></use>
                        </svg>
                    </li>
                    <li>
                        Chevrons
                        <svg class="icon icon--chevron-up" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-chevron-up" href="#icon-chevron-up"></use>
                        </svg>
                        <svg class="icon icon--chevron-down" viewBox="0 0 36 36" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-chevron-down" href="#icon-chevron-down"></use>
                        </svg>
                    </li>
                </ul>
                <h2>
                    Sign-in icons
                </h2>
                <ul>
                    <li>
                        Register
                        <svg class="icon icon--register" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-register" href="#icon-register"></use>
                        </svg>
                    </li>
                    <li>
                        Email
                        <svg class="icon icon--email" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-email" href="#icon-email"></use>
                        </svg>
                    </li>
                    <li>
                        Loading spinner
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 48px; height: 48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block">
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9375s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(22.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.875s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(45 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8125s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(67.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(90 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6875s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(112.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.625s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(135 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5625s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(157.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(180 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4375s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(202.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.375s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(225 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3125s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(247.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(270 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1875s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(292.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.125s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(315 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.0625s" repeatCount="indefinite"/>
                            </rect>
                            <rect x="48.5" y="23.5" rx="1.5" ry="2.21" width="3" height="13" fill="#000" transform="rotate(337.5 50 50)">
                              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/>
                            </rect>
                          </svg>
                    </li>
                </ul>

                <h2>Website-specific icons</h2>
                <ul class="no-bullet">
                    <li>
                        Sign-in 
                        <svg class="icon icon--sign-in" viewBox="0 0 24 24" style="width: 24px; height: 24px">
                            <use xlink:href="#icon-sign-in" href="#icon-sign-in"></use>
                        </svg>
                    </li>
                    <li>
                        Search 
                        <svg class="icon icon--search" viewBox="0 0 24 24" style="width: 1.875rem; height: 1.875rem">
                            <use xlink:href="#icon-search" href="#icon-search"></use>
                        </svg>
                    </li>
                    <li>
                        Hamburger mobile nav 
                        <svg class="icon icon--hamburger" viewBox="0 0 24 24" style="width: 48px; height: 48px">
                            <use xlink:href="#icon-hamburger" href="#icon-hamburger"></use>
                        </svg>
                    </li>
                    <li>
                        Chevron right 
                        <svg class="icon icon--chevron-right" viewBox="0 0 24 24" style="width: 36px; height: 36px">
                            <use xlink:href="#icon-chevron-right" href="#icon-chevron-right"></use>
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</main>


