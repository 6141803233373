import { AbstractControl, ValidationErrors } from '@angular/forms';

export function verificationCodeValidator (control: AbstractControl): ValidationErrors | null {
  const verificationCode = control.value;
  if (!verificationCode) {
    return null
  };
  const verificationCodePattern = /^[0-9]*$/;
  if (!verificationCodePattern.test(verificationCode)) {
    return { 'notValidNumber': true }
  }
  if (verificationCode.length < 6) {
    return { 'codeIsTooShort': true }
  }
  if (verificationCode.length > 6) {
    return { 'codeIsTooLong': true }
  }
  return null;
}
