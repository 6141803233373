declare const window: any;
export const COOKIE_CONFIG = {
  apiKey: "d3ae477ba8f6f7a024788c373df9f0e02b59fab2",
  product: "PRO_MULTISITE",
  logConsent: false,
  notifyOnce: false,
  initialState: "NOTIFY",
  position: "RIGHT",
  theme: "DARK",
  layout: "SLIDEOUT",
  toggleType: "checkbox",
  acceptBehaviour: "all",
  
  closeOnGlobalChange: true,
  iabCMP: false,
  closeStyle: "icon",
  consentCookieExpiry: 90,
  subDomains: true,
  mode: "gdpr",
  rejectButton: true,
  settingsStyle: "button",
  encodeCookie: false,
  setInnerHTML: true,
  accessibility: {
    accessKey: "C",
    highlightFocus: false,
    outline: true,
    overlay: true,
  },
  text: {
    title: "This site uses cookies",
    intro:
      "'Cookies' are small pieces of text we store on your device. We can't identify who you are by any of the cookies we collect. Cookies help Learn My Way to work and can improve your experience.",
    necessaryTitle: "Necessary cookies",
    necessaryDescription:
      "We use these necessary cookies to make Learn My Way work. These cookies are always stored.",
    thirdPartyTitle: "Warning: Some cookies require your attention",
    thirdPartyDescription:
      "Consent for the following cookies could not be automatically withdrawn. Please follow the link(s) below to opt out manually.",
    on: "On",
    off: "Off",
    accept: "Accept all cookies",
    settings: "I don't understand this",
    acceptRecommended: "Accept recommended settings",
    acceptSettings: "Accept all cookies",
    notifyTitle: "It's your choice whether you want 'cookies' to be stored",
    notifyDescription:
      "We use cookies to make Learn My Way work and give you the best possible experience.",
    closeLabel: "Close",
    cornerButton: "Set cookie preferences.",
    landmark: "Cookie preferences.",
    showVendors: "Show vendors within this category",
    thirdPartyCookies: "This vendor may set third party cookies.",
    readMore: "Read more",
    accessibilityAlert:
      "This site uses cookies to store information. Press accesskey C to learn more about your options.",
    rejectSettings: "Reject unnecessary cookies",
    reject: "Reject unnecessary cookies",
  },
  branding: {
    fontColor: "#fff",
    fontFamily: "Rubik,Helvetica Neue,Helvetica,Arial,sans-serif",
    fontSizeTitle: "1.4em",
    fontSizeHeaders: "1.2em",
    fontSize: "1em",
    backgroundColor: "#027DBB",
    toggleText: "#000000",
    toggleColor: "#000000",
    toggleBackground: "#ffffff",
    alertText: "#fff",
    alertBackground: "#027DBB",
    acceptText: "#000000",
    acceptBackground: "#ffffff",
    rejectText: "#000000",
    rejectBackground: "#ffffff",
    closeText: "#ffffff",
    closeBackground: "#027DBB",
    notifyFontColor: "#FFF",
    notifyBackgroundColor: "#027DBB",
    buttonIcon: null,
    buttonIconWidth: "64px",
    buttonIconHeight: "64px",
    removeIcon: false,
    removeAbout: true,
  },
  necessaryCookies: ["CookieControl", "CookieConsent"],

  optionalCookies: [
    {
      name: "analytics",
      label: "Optional cookies",
      description:
        "These optional cookies (known as 'analytics cookies') help us to improve everyone's experience. They provide us with information about how Learn My Way is being used.",
      cookies: [
        "_ga",
        "_gat",
        "_gid",
      ],
      onAccept: function() {
        window.gtag('consent', 'update', {'analytics_storage': 'granted'});
      },
      onRevoke: function() {
        window.gtag('consent', 'update', {'analytics_storage': 'denied'});
      },
      recommendedState: "off",
      lawfulBasis: "consent",
    },
  ],
  sameSiteCookie: true,
  sameSiteValue: "Strict",
  notifyDismissButton: false,
};
