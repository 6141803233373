import { Injectable } from '@angular/core';
import { TextToSpeechFragment } from '../classes/text-to-speech-fragment.class';
import { TextToSpeechProvider } from '../providers/text-to-speech.provider';
import { AmplifyApiService } from "@nx/amplify-api";

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  private cache: { [id: string]: HTMLAudioElement } = {};
  private audio: HTMLAudioElement = new Audio();

  private voice: string = 'Amy';
  private voiceSpeed: string = 'slow'

  constructor(private textToSpeechProvider: TextToSpeechProvider, private amplifyApiService: AmplifyApiService) {}

  play(fragment: TextToSpeechFragment): void {
    if (this.isAudioPlaying()) {
      this.silence();
    } else {
      if (fragment.id in this.cache) {
        this.audio = this.cache[fragment.id];
        this.audio.currentTime = 0;
        this.audio.play();
      } else {
        this.load(fragment);
      }
    }
  }

  silence(): void {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  setVoice(voice: string): void {
    this.voice = voice;
  }

  setVoiceSpeed(voiceSpeed: string): void {
    this.voiceSpeed = voiceSpeed;
  }

  isAudioPlaying(): boolean {
    return !this.audio.paused;
  }

  private load(fragment: TextToSpeechFragment): void {
    if (fragment.text.length === 0) {
      return;
    }

    this.cache[fragment.id] = new Audio();

    const url = this.textToSpeechProvider.apiUrl;
    this.amplifyApiService.publicPost(url, {
      text: fragment.text,
      guid: fragment.id,
      format: 'mp3',
      voice: this.voice,
      speed: this.voiceSpeed,
      groupBy: ['learnmyway']
    }).subscribe((response) => {
      const audio = this.cache[fragment.id];
      audio.preload = 'auto';
      audio.src = response;
      audio.load();
      audio.addEventListener('ended', () => {
        if (audio.src === this.audio.src) {
          this.silence();
        }
      })
      this.play(fragment);
    });
  }
}