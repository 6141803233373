import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  LearnMyWaySvgSpriteComponent
} from './learn-my-way-svg-sprite/learn-my-way-svg-sprite/learn-my-way-svg-sprite.component';
import { AmplifyApiEnvironment } from '@nx/amplify-api';
import { AuthProcessProvider } from './features/auth-process/providers/auth-process-provider';
import { countryCodeISO } from './features/auth-process/providers/country-code';
import { lmwAuthLazyLoadedState } from './features/auth-process/common/routing/route-locales/app-routes';
import { environment } from '../environments/environment';
import { unitPlayerLazyLoadedState } from './features/topic-player/routing/route-locales/unit-player-routes';
import { ErrorModule, ErrorNotificationService, ErrorProvider } from '@nx/error';
import { lmwBaseRoutes } from './features/lmw-base/routing/route-locales/lmw-base-routes';
import { StrapiProvider, StrapiService } from '@nx/strapi';
import { AppInitialiseService } from './services/app-initialise.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalErrorHandler } from './services/error-handler.service';
import { GoogleAnalyticsService, TextToSpeechProvider } from "@nx/mono-common";
import { HttpServerErrorInterceptor, AxiosServerErrorInterceptor, ServerErrorInterceptorFactory } from "@nx/error";
import { TextToSpeechService } from '@nx/mono-common';

const routes = [
  {
    path: unitPlayerLazyLoadedState.path,
    loadChildren: () =>
      import('./features/topic-player/topic-player.module').then(
        (mod) => mod.TopicPlayerModule
      ),
  },
  environment.loadAuth
    ? {
        path: lmwAuthLazyLoadedState.path,
        loadChildren: () =>
          import('./features/auth-process/lmw-auth.module').then((mod) => mod.LmwAuthModule),
      }
    : null,
  {
    path: 'svg-sprite',
    component: LearnMyWaySvgSpriteComponent,
  },
  {
    path: lmwBaseRoutes.lazyLoadedPath,
    loadChildren: () =>
      import('./features/lmw-base/lmw-base.module').then(
        (mod) => mod.LmwBaseModule
      ),
  },
].filter(Boolean);

@NgModule({
  declarations: [AppComponent, LearnMyWaySvgSpriteComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    ErrorModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  providers: [
    { provide: AmplifyApiEnvironment, useValue: environment },
    { provide: LOCALE_ID, useValue: environment.localeId },
    { provide: countryCodeISO, useValue: environment.countryCode },
    {
      provide: AuthProcessProvider,
      useValue: {
        applicationName: environment.applicationName,
        appVersion: environment.appVersion,
        langLocale: environment.localeId,
        language: environment.language,
        privacyLinkPath: lmwBaseRoutes.privacyStatePath,
        termsAndConditionsLinkPath: lmwBaseRoutes.termsAndConditionsStatePath,
      },
    },
    {
      provide: ErrorProvider,
      useValue: { applicationName: environment.applicationName },
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: StrapiProvider,
      useValue: {
        strapiBaseUrl: environment.strapiBaseUrl,
        strapiApiUrl: environment.strapiApiUrl,
      },
    },
    {
      provide: TextToSpeechProvider,
      useValue: {
        apiUrl: environment.textToSpeechUrl
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initialiseService: AppInitialiseService, strapiService: StrapiService) => () => {
        initialiseService.autoUserCheck();
        strapiService.get('errors/generic-popup').toPromise();
      },
      deps: [AppInitialiseService, StrapiService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (analyticsService: GoogleAnalyticsService) => () => {
        if (environment.enableAnalytics && environment.ga_measurement_id) {
          analyticsService.loadGoogleAnalytics(environment.ga_measurement_id);
        }
      },
      deps: [GoogleAnalyticsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ServerErrorInterceptorFactory,
      deps: [AxiosServerErrorInterceptor],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServerErrorInterceptor,
      deps: [ErrorNotificationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (textToSpeechService: TextToSpeechService) => () => {
        if (environment.tts) {
          textToSpeechService.setVoice(environment.tts.voice);
          textToSpeechService.setVoiceSpeed(environment.tts.voiceSpeed);
        }
      },
      deps: [TextToSpeechService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
