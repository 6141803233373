<main class="grid-container site-container site-container--error-page" id="content">
  <div class="grid-x grid-margin-x">
      <div class="cell">
        <h1 class="secondary" >
          {{ title ? title : "Sorry, you can't access the page you were looking for (401 error)" }}
        </h1>
        <div
          [innerHTML]="description ? description : 'This is probably due to security issues, it\'s nothing to worry about.' | dontSanitizeHtml"></div>
        <ng-content select="[extraContentLower]"></ng-content>
      </div>
  </div>
</main>
