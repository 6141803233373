import { Component, computed, EventEmitter, OnDestroy, OnInit, Output, input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Help } from '../../abstract/help.abstract';
import { HelpStoreService } from '../../services/help-store.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mono-common-help',
  templateUrl: './help.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class HelpComponent extends Help implements OnInit, OnDestroy {

  action = input.required<string>();
  closeButtonText = input.required<string>();
  secondaryButtonText = input<string>();
  removeHelpMessage = input<EventEmitter<string>>();

  @Output() closeButtonExtras?: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondaryButtonExtras?: EventEmitter<any> = new EventEmitter<any>();

  public showHelp = computed(() => this.helpStoreService.selectHelpAction()() === this.action());

  private stopSubscriber = new Subject();

  constructor(private helpStoreService: HelpStoreService) {
    super();
  }

  ngOnInit(): void {
    this.onRemoveHelpMessage();
  }

  public closeHelpMessage() {
    this.destroyWindowResize();
    this.helpStoreService.dispatchUpdateHelpStore(null);
  }

  public closeButtonExtrasEmit($event) {
    this.closeButtonExtras.emit($event);
  }

  public secondaryButtonExtrasEmit($event) {
    this.secondaryButtonExtras.emit($event);
  }

  private onRemoveHelpMessage() {
    if(this.removeHelpMessage()) {
      this.removeHelpMessage().pipe(takeUntil(this.stopSubscriber))
        .subscribe((action) => { if(action === this.action() && this.showHelp) this.closeHelpMessage() });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closeHelpMessage();
    this.stopSubscriber.next();
    this.stopSubscriber.complete();
  }

}