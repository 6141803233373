export const completeState = {
  path: 'complete'
}

export const subjectCompleteState = {
  path: 'subject-complete'
}

export const stageState = {
  path: 'stage'
}

export const unitPlayerLazyLoadedState = {
  path: 'topic-player'
}