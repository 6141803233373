import { Action, createReducer, on } from '@ngrx/store';
import { HeaderCommonStore } from '../interfaces/header-common-store';
import { UpdateCommonHeaderStore } from './app.actions';

export const initialAppState: HeaderCommonStore = {
  previousRoute: ''
};

const reducer = createReducer(
  initialAppState,
  on(UpdateCommonHeaderStore, (state, { previousRoute }) => Object.assign({}, state, { ...previousRoute }))
);

export function commonHeaderReducer(state: HeaderCommonStore | undefined, action: Action) {
  return reducer(state, action);
};