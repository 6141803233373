import { ErrorHandler, Injectable } from '@angular/core';
import { AmplifyApiService } from '@nx/amplify-api';
import { AwsAuthService } from '@nx/auth';
import { from, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private amplifyApiService: AmplifyApiService, private awsAuthService: AwsAuthService) {}

  handleError(error: Error) {
    console.error(error);
    if(!this.dontPostError(error)) {
      this.postError(error).subscribe();
    }
  }

  private postError(error: Error) {
    const filteredError = { message: error.message, name: error.name, stack: error.stack }
    return from(this.getCurrentUser()).pipe(
      switchMap((user) =>
      this.amplifyApiService.publicPost('/core-new/log-error', { error: { ...filteredError, ...user && { user: user } } })),
      take(1),
      catchError(() => of(false))
    );
  }

  private dontPostError(error: Error): boolean {
    const errorsToNotPost = ["The user is not authenticated"];
    return errorsToNotPost.some(errorToNotPost => errorToNotPost === error.message);
  }

  private async getCurrentUser() {
    try {
      const user = await this.awsAuthService.getFullCurrentUser();
      return {
        ...user.attributes.email && { email: user.attributes.email },
        ...user.attributes.phone_number && { phone_number: user.attributes.phone_number }
      }
    }
    catch (err) {
      return false;
    }
  }
}