import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {  CountryCode } from 'libphonenumber-js/mobile';
import { postcodeValidator } from 'postcode-validator';

export function postcodeValidation(countryCode: CountryCode, errorMessage = `Must be a valid postcode format.`): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    };
    if(postcodeValidator(control.value, countryCode)) {
      return null
    }
    return { notValidPostCode: { errorMessage } };
  };
}