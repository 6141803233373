import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AwsAuthService } from '@nx/auth';
import { filter, map } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CivicCookieControlService, IdleTimer } from '@nx/mono-common';
import { COOKIE_CONFIG } from "./config/cookie-config";
import { environment } from "../environments/environment";
import { StrapiService } from "@nx/strapi";
import { Store } from '@ngrx/store';
import { CloseGlobalErrorPopup, errorPopupIsOpenSelector, ErrorStore } from "@nx/error";
import { ErrorCms } from "./features/lmw-error/interfaces/error-cms.interface";

@Component({
  selector: 'nx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public skipToContentLink: string;
  public userLoggedIn = false;
  public errorPopupIsOpen$: Observable<boolean>;
  private navigationSubscription: Subscription;
  private userInactivityObservable: Subject<any> = new Subject();
  private idleTimer: IdleTimer;

  public cms: ErrorCms;

  @ViewChild('contentSkip', { static: true }) contentSkip: ElementRef;

  constructor(
    private AwsAuthService: AwsAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private strapiService: StrapiService,
    private store: Store<ErrorStore>,
    public cookieService: CivicCookieControlService
  ) {

  }
  ngOnInit() {
    this.getCurrentLinkWithoutAnchors();
    this.setRouteTitle();
    this.userInactivityObservable.subscribe(() => {
      if (this.userLoggedIn) {
        this.AwsAuthService.logout().then(() => {
          this.userLoggedIn = false;
          window.location.href = "/";
        })
      }
    });
    this.strapiService.get('errors/generic-popup').subscribe(genericErrorCms => {
      this.cms = genericErrorCms;
    });
    this.errorPopupIsOpen$ = this.store.select(errorPopupIsOpenSelector);

    if (environment.enableAnalytics) {
      this.cookieService.loadCookieControlScript(COOKIE_CONFIG);
    }
  }

  private getCurrentLinkWithoutAnchors() {
    this.navigationSubscription = this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)))
      .subscribe((event: NavigationEnd) => {
        if(!event.url.includes('#')) {
          this.skipToContentLink = event.url.split('#')[0];
          this.contentSkip.nativeElement.focus();
        }
        this.checkIfUserIsLoggedIn();
      });
  }

  private setRouteTitle() {
    const indexTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.createRouteTitle(indexTitle))
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  private createRouteTitle(indexTitle: string) {
    let child = this.activatedRoute.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data['title']) {
      return child.snapshot.data['title'];
    }
    return indexTitle;
  }

  private checkIfUserIsLoggedIn() {
    this.AwsAuthService.isUserLoggedIn().then((user) => {
      this.userLoggedIn = user;
      if (user) {
        this.idleTimer = new IdleTimer({
          timeoutInSeconds: 3600,
          onTimeout: () => {
            this.userInactivityObservable.next();
            this.idleTimer.cleanUp();
          }
        });
      }
    })
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public closeErrorPopup(): void {
    this.store.dispatch(CloseGlobalErrorPopup());
  }
}
