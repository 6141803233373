import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpStoreService {

  private helpActionSignal = signal<string | null>(null);

  public selectHelpAction() {
    return this.helpActionSignal;
  }

  public dispatchUpdateHelpStore(action: string) {
    this.helpActionSignal.set(action);
  }
}