import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare const CookieControl: any;

@Injectable({
  providedIn: 'root'
})
export class CivicCookieControlService {

	constructor(@Inject(DOCUMENT) private document: any) {}

	public loadCookieControlScript(cookieConfig) {
    CookieControl.load(cookieConfig);
  }
}
