import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorStore } from "../interfaces/error-store.interface";
import { CloseGlobalErrorPopup, OpenGlobalErrorPopup } from "../store/error.actions";

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {
  private errorHandlingDisabled: boolean;

  constructor(private store: Store<ErrorStore>) { }

  public showGeneralError(): void {
    if (!this.errorHandlingDisabled) {
      this.store.dispatch(OpenGlobalErrorPopup());
    }
  }

  public closeGeneralError(): void {
    if (!this.errorHandlingDisabled) {
      this.store.dispatch(CloseGlobalErrorPopup());
    }
  }

  public isErrorHandlingDisabled(): boolean {
    return this.errorHandlingDisabled;
  }
  public enableErrorHandling(): void {
    this.errorHandlingDisabled = false;
  }
  public disableErrorHandling(): void {
    this.errorHandlingDisabled = true;
  }
}
