<aside
  #helpBox
  *ngIf="open"
  role="alert"
  class="help-popup"
  tabindex="-1"
  [ngClass]="[
    helpPopupPosition !== '' ? 'help-popup--' + helpPopupPosition : '',
    shadow ? 'big-shadow' : '',
    helpPopupCalledFrom !== '' ? 'help-popup--' + helpPopupCalledFrom : ''
  ]"
  [@slideInUpwards]
  [@.disabled]="!enableAnimations"
>
  <div *ngIf="pointer" class="pointer" aria-hidden="true"></div>
  <div class="flex-container align-justify">
    <h3>
      <svg class="icon icon--light icon--help-circle" viewBox="0 0 24 24">
        <use xlink:href="#icon-help" href="#icon-help"></use>
      </svg>
      <span data-test="mono-common-help-popup-title">{{ title }}</span>
    </h3>
    <button
      data-test="mono-common-help-popup-close-button"
      class="button close-button"
      type="button"
      (click)="closeHelpPopup()"
      (mousedown)="preventFocusOnElementIfPointerPresent($event)"
    >
      <svg class="icon icon--exit icon--light">
        <use xlink:href="#icon-exit" href="#icon-exit"></use>
      </svg>
    </button>
  </div>
  <div
    class="flex-container flex-dir-column"
    data-test="mono-common-help-popup-message"
    [innerHTML]="message | dontSanitizeHtml"
  ></div>
</aside>
