import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { commonHeaderReducer } from "./store/app.reducer";
import { HeaderService } from "./services/header.service";
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalHeaderLocationGuard } from "./routing/location.guard";

@NgModule({
  imports: [
    CommonModule,
      StoreModule.forFeature('commonHeader', commonHeaderReducer)
  ],
  declarations: [ModalHeaderComponent],
  exports: [ModalHeaderComponent],
  providers: [HeaderService, ModalHeaderLocationGuard]
})

export class ModalHeaderModule { }