import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { LoadingAnimationNetworkComponent } from './components/loading-animation-network/loading-animation-network.component';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BackToNavigationBarComponent } from './components/back-to-navigation-bar/back-to-navigation-bar.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MobileRegisterSignInBarComponent } from './components/mobile-register-sign-in-bar/mobile-register-sign-in-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderModule } from './features/header/header.module';
import { CalloutComponent } from './components/callout/callout.component';
import { FooterNavigationComponent } from './components/footer-navigation/footer-navigation.component';
import { DontSanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalWithContentComponent } from './components/modal-with-content/modal-with-content.component';
import { HelpPopupComponent } from './components/help-popup/help-popup.component';
import { BlockquoteImageComponent } from './components/blockquote-image/blockquote-image.component';
import { InputWithDoneButtonComponent } from './components/input-with-done-button/input-with-done-button.component';
import { ShowHidePasswordToggleComponent } from './components/show-hide-password-toggle/show-hide-password-toggle.component';
import { PasswordHelpPopupComponent } from './components/password-help-popup/password-help-popup.component';
import { TextToSpeechModule } from './features/text-to-speech/text-to-speech.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MonoFormsModule } from './features/mono-forms/mono-forms.module';
import { ResendVerificationCodeComponent } from './components/resend-verification-code/resend-verification-code.component';
import { ResendUpdateAttributesCodeComponent } from './components/resend-update-attributes-code/resend-update-attributes-code.component';
import { ShareWithMembersComponent } from './features/mono-forms/components/share-with-members/share-with-members.component';
import { ShareWithDigitalInclusionThirdPartyComponent } from './features/mono-forms/components/share-with-digitial-inclusion-third-party/share-with-digital-inclusion-third-party.component';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  declarations: [
    LoadingAnimationComponent,
    LoadingAnimationNetworkComponent,
    BreadcrumbsComponent,
    BackToNavigationBarComponent,
    ProgressBarComponent,
    MobileRegisterSignInBarComponent,
    FooterComponent,
    CalloutComponent,
    FooterNavigationComponent,
    DontSanitizeHtmlPipe,
    ModalConfirmationComponent,
    ModalWithContentComponent,
    HelpPopupComponent,
    BlockquoteImageComponent,
    InputWithDoneButtonComponent,
    ShowHidePasswordToggleComponent,
    PasswordHelpPopupComponent,
    ResendVerificationCodeComponent,
    ResendUpdateAttributesCodeComponent,
    ShareWithMembersComponent,
    ShareWithDigitalInclusionThirdPartyComponent
  ],
  exports: [
    HeaderModule,
    LoadingAnimationComponent,
    LoadingAnimationNetworkComponent,
    BreadcrumbsComponent,
    BackToNavigationBarComponent,
    ProgressBarComponent,
    MobileRegisterSignInBarComponent,
    FooterComponent,
    CalloutComponent,
    FooterNavigationComponent,
    DontSanitizeHtmlPipe,
    ModalConfirmationComponent,
    ModalWithContentComponent,
    HelpPopupComponent,
    BlockquoteImageComponent,
    InputWithDoneButtonComponent,
    ShowHidePasswordToggleComponent,
    PasswordHelpPopupComponent,
    TextToSpeechModule,
    MonoFormsModule,
    ResendVerificationCodeComponent,
    ResendUpdateAttributesCodeComponent,
    ShareWithMembersComponent,
    ShareWithDigitalInclusionThirdPartyComponent
  ],
})
export class MonoCommonModule {}
