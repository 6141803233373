<main class="grid-container site-container site-container--error-page" id="content">
  <div class="grid-x grid-margin-x">
      <div class="cell">
        <h1 class="secondary" >
          {{ title ? title : "Sorry, something went wrong with " + errorProvider.applicationName + " (400 error)" }}
        </h1>
        <div
          [innerHTML]="description ? description : 'It looks like something technical went wrong with ' + errorProvider.applicationName + '. We\'re working to fix it.' | dontSanitizeHtml">
        </div>
        <ng-content select="[extraContentLower]"></ng-content>
      </div>
  </div>
</main>
