import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StrapiProvider } from '../providers/strapi-provider';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

  private cache: { [name: string]: Observable<any> } = {};

  constructor(private strapiProvider: StrapiProvider, private http: HttpClient) { }

  public get(path: string): Observable<{ data: any, meta: any}> {
    if (!this.cache[path]) {
      this.cache[path] = this.http.get<{ data: any, meta: any}>
        (
          `${this.strapiProvider.strapiBaseUrl}/${this.strapiProvider.strapiApiUrl}/${path}`).pipe(publishReplay(), refCount()
        );
    }
    return this.cache[path];
  }

}