import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppStates } from './routing/app.states';
import { ErrorModule } from './error.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([...AppStates])
  ],
  exports: [ErrorModule]
})
export class ErrorRoutedModule {}