import { AbstractControl } from '@angular/forms';

export function fullNameValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const letterFromTheAlphabet = /[A-Z]/i
  const validCharacters = /[*|\":.<>%[\]{}`\\();@&$^0-9£!_+=?/~±§€#]/;
  const name = control.value;

  if (!name) {
    return null
  }
  if (!letterFromTheAlphabet.test(name)) {
    return { 'noLettersProvidedInName': true }
  }
  if (validCharacters.test(name)) {
    return { 'invalidCharacters': true }
  }
  return null
}
