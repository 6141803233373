import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BadRequestComponent } from './components/bad-request/bad-request.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { MonoCommonModule } from "@nx/mono-common";
import { GlobalErrorPopupComponent } from "./components/global-error-popup/global-error-popup.component";
import { errorReducer } from "./store/error.reducer";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MonoCommonModule,
    StoreModule.forFeature('error', errorReducer),
  ],
  declarations: [NotFoundComponent, BadRequestComponent, ForbiddenComponent, UnauthorizedComponent, InternalServerErrorComponent, GlobalErrorPopupComponent],
  exports: [NotFoundComponent, BadRequestComponent, ForbiddenComponent, UnauthorizedComponent, InternalServerErrorComponent, GlobalErrorPopupComponent]
})
export class ErrorModule {}
