import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { StrapiService } from "./strapi.service";
import { catchError, finalize, map } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { ErrorNotificationService } from "@nx/error";

@Injectable({
  providedIn: 'root'
})

export class StrapiResolver  {

  constructor(private strapiService: StrapiService, private router: Router, private errorNotificationService: ErrorNotificationService) { }
  resolve(route: ActivatedRouteSnapshot): any {
    if(route.routeConfig.data.strapiUrl) {
      this.errorNotificationService.disableErrorHandling();
      return this.strapiService.get(route.routeConfig.data.strapiUrl).pipe(
        catchError((e) => this.handleError(route, e)),
        finalize(() => this.errorNotificationService.enableErrorHandling())
      );
    }
    else {
      const calls = this.getStrapiUrlsCalls(route.routeConfig.data.strapiUrls);
      return forkJoin(calls).pipe(
        map(content => content.reduce((acc, content) => ({ ...acc, ...content }), {})),
        catchError((e) => this.handleError(route, e))
      )
    }
  }

  private getStrapiUrlsCalls(strapiUrls) {
    return Object.entries<string>(strapiUrls).map(strapiUrl =>
      this.strapiService.get(strapiUrl[1]).pipe(map((content) => ({ [strapiUrl[0]]: content })))
    )
  }

  private handleError(route: ActivatedRouteSnapshot, e) {
    return route.routeConfig.path !== "404" ? this.router.navigateByUrl("/404") : of(e);
  }
}