import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import {  publishReplay, refCount } from 'rxjs/operators';
import * as translate from "translate";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private cache: { [name: string]: Observable<string> } = {};

  constructor() { }

  public translate(template: string, language: string): Observable<string> {
    if (!this.cache[template]) {
      this.cache[template] = from<string>(translate(template, language)).pipe(publishReplay(), refCount());
    }
    return this.cache[template];
  }

}
