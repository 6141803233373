import { AmplifyApiEnvironment } from "@nx/amplify-api";

class EnvironmentImpl implements AmplifyApiEnvironment {
  production = true;
  applicationName = "Learn My Way";
  appVersion = "uk";
  current = "PROD";
  localeId = "en-gb";
  countryCode = "GB";
  language = "en";
  strapiBaseUrl = "https://nextgen-strapi.learnmyway.com";
  strapiApiUrl = "api";
  loadAuth = true
  loadLocalSupport = true;
  loadCookies = false;
  tts = {
    voice: 'Amy',
    voiceSpeed: 'slow'
  };
  amplify = {
    Auth: {
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_V6iw0ziLN',
      userPoolWebClientId: '2o36cmi4pfum5jrh7o57m58nn2',
      endpoint: 'https://www.learnmyway.com/cognito-idp'
    },
    API: {
      endpoints: [
        {
          name: 'public-api',
          endpoint: 'https://nextgen-uk-api.learnmyway.com',
        }
      ],
      apiKey: '7D3NM4N5Xb5HuORzQzNRP86XTNjMOvNx89NrD0xP'
    },
    storage: sessionStorage
  };
  ga_measurement_id = 'G-0BNCGMR17T';
  enableAnalytics = true;
  textToSpeechUrl = "/core-new/text-to-speech"
}

export const environment = new EnvironmentImpl();
