import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function forbiddenInputsValidator(blacklist: string[]): ValidatorFn {
  blacklist = blacklist || [];

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    for (const token of blacklist) {
      const tokenAsRegex = new RegExp(token, 'i');
      const isForbidden = tokenAsRegex.test(control.value.trim());
      return isForbidden ? {forbiddenInput: {value: control.value}} : null;
    }
  };

  return null;
}
