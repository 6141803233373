import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { HeaderUserLinksComponent } from './components/header-user-links/header-user-links.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { HeaderHelpComponent } from './components/header-help/header-help.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';


@NgModule({
  declarations: [
    HeaderComponent,
    HeaderUserLinksComponent,
    HeaderLogoComponent,
    HeaderHelpComponent,
    HeaderSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    HeaderUserLinksComponent,
    HeaderLogoComponent,
    HeaderHelpComponent,
    HeaderSearchComponent
  ]
})
export class HeaderModule { }
