import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { NewUser } from '../interfaces/new-user.interface';
import { take } from 'rxjs/operators';
import { ClientMetadata } from '../interfaces/client-meta-data.interface';
import { UpdateCognitoUser } from '../interfaces/update-cognito-user.interface';
import { UserClaims } from '../interfaces/user-claims.interface';

@Injectable({
  providedIn: 'root',
})
export class AwsAuthService {
  constructor() {}

  public register(newUser: NewUser): Observable<any> {
    return from(this.registerAmplify(newUser)).pipe(take(1));
  }

  public confirmSignUp(signInAttribute: string, code: string, clientMetadata?: ClientMetadata): Observable<any> {
    return from(this.confirmSignUpAmplify(signInAttribute, code)).pipe(take(1));
  }

  public login(signInAttribute: string, password: string, clientMetadata?: ClientMetadata): Observable<any> {
    return from(this.loginAmplify(signInAttribute, password)).pipe(take(1));
  }

  public sendResetPasswordCode(username: string, clientMetadata?: ClientMetadata) {
    return from(this.sendForgotPasswordCodeAmplify(username, clientMetadata)).pipe(take(1));
  }

  public createNewPassword(
    username: string,
    code: string,
    newPassword: string
  ) {
    return from(
      this.createNewPasswordAmplify(username, code, newPassword)
    ).pipe(take(1));
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return from(this.changePasswordAmplify(oldPassword, newPassword)).pipe(
      take(1)
    );
  }

  public sendVerificationCode(cognitoAttributes: UpdateCognitoUser, clientMetadata?: ClientMetadata) {
    return from(this.updateUserAttributesAmplify(cognitoAttributes, clientMetadata)).pipe(take(1));
  }

  public sendNewUpdateVerificationCode(cognitoAttribute: string, clientMetadata?: ClientMetadata) {
    return from(this.verifyUserAttributeAmplify(cognitoAttribute, clientMetadata)).pipe(take(1));
  }

  public sendNewSignUpVerificationCode(username: string, clientMetadata?: ClientMetadata) {
    return from(this.resendSignUpAmplify(username, clientMetadata)).pipe(take(1));
  }

  public updateSignInAttribute(code: string, attributeType: string) {
    return from(this.verifyUserAttributeSubmitAmplify(code, attributeType)).pipe(
      take(1)
    );
  }

  public getFullCurrentUser() {
    return this.getCurrentUserAmplify();
  }

  public getCurrentUserClaims(): Promise<UserClaims> {
    return this.getCurrentUserSessionAmplify().then((session) => ({
        user: JSON.parse(session.idToken.payload.user)
      }));
  }

  public getCurrentUsername() {
    return this.getCurrentUserAmplify().then((user) => user.signInUserSession.accessToken);
  }

  public getUserFullName(): Promise<string> {
    return this.getCurrentUserAmplify().then((user) => 
       user.attributes.name ? user.attributes.name : '' //Prop "name" moved to dynamoDB as "fullname"
    );
  }

  public async isUserLoggedIn(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (err) {
      return false;
    }
  }

  public logout(): Promise<any> {
    return this.logoutAmplify();
  }

  public async refreshToken(callback?) {
    callback = callback || (() => {});
    const currentUser = await this.getCurrentUserAmplify();
    const currentSession = await this.getCurrentUserSessionAmplify();
    currentUser.refreshSession(currentSession.refreshToken, callback);
  }

  private async logoutAmplify(): Promise<any> {
    try {
      return await Auth.signOut();
    } catch (err) {
      throw new Error(err);
    }
  }

  private async getCurrentUserAmplify(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (err) {
      throw new Error(err);
    }
  }

  private async getCurrentUserSessionAmplify(): Promise<any> {
    try {
      return await Auth.currentSession();
    } catch (err) {
      throw new Error(err);
    }
  }

  private async registerAmplify(newUser: NewUser): Promise<any> {
    try {
      return await Auth.signUp(newUser);
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async confirmSignUpAmplify(signInAttribute: string, code: string): Promise<any> {
    try {
      return await Auth.confirmSignUp(signInAttribute, code);
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async loginAmplify(signInAttribute: string, password: string): Promise<any> {
    return await Auth.signIn(signInAttribute, password);
  }

  private async sendForgotPasswordCodeAmplify(username: string, clientMetadata?: ClientMetadata): Promise<any> {
    try {
      return await Auth.forgotPassword(username, clientMetadata);
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async changePasswordAmplify(
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    try {
      return await Auth.currentAuthenticatedUser().then(user =>
        Auth.changePassword(user, oldPassword, newPassword));
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async updateUserAttributesAmplify(cognitoAttributes: UpdateCognitoUser, clientMetadata?: ClientMetadata): Promise<any> {
    try {
      return await Auth.currentAuthenticatedUser().then((user) =>
        Auth.updateUserAttributes(user, {
          ...cognitoAttributes
        }, clientMetadata));
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async verifyUserAttributeAmplify(cognitoAttribute: string, clientMetadata?: ClientMetadata): Promise<any> {
    try {
      return await Auth.currentAuthenticatedUser().then((user) =>
        Auth.verifyUserAttribute(user, cognitoAttribute, clientMetadata));
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async resendSignUpAmplify(username: string, clientMetadata?: ClientMetadata): Promise<any> {
    try {
      return await Auth.resendSignUp(username, clientMetadata);
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async verifyUserAttributeSubmitAmplify(
    code: string,
    type: string
  ): Promise<any> {
    try {
      return await Auth.currentAuthenticatedUser().then((user) =>
        Auth.verifyUserAttributeSubmit(user, type, code));
    } catch (err) {
      throw new Error(err.code);
    }
  }

  private async createNewPasswordAmplify(
    username: string,
    code: string,
    newPassword: string
  ): Promise<any> {
    try {
      return await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (err) {
      throw new Error(err.code);
    }
  }
}
