import { Component, Input, OnInit } from '@angular/core';
import { ErrorProvider } from '../../providers/error-provider';

@Component({
  selector: 'error-internal-server-error',
  templateUrl: './internal-server-error.component.html'
})
export class InternalServerErrorComponent implements OnInit {

  @Input() public title?: string;
  @Input() public description?: string;

  constructor(public errorProvider: ErrorProvider) { }

  ngOnInit(): void {
  }

}
