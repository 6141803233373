<aside role="alert" *ngIf="showHelp()" class="help-popup" [ngClass]="helpPopupCalledFrom ? 'help-popup--'+helpPopupCalledFrom : 'help-popup--'+helpPopupPosition" tabindex="-1" #helpBox [ngStyle]="helpPopupPosition === 'top' ? {'bottom.px': helpPopupAbsolutePositioning} : {'top.px': helpPopupAbsolutePositioning}">
  <div class="pointer" aria-hidden="true"></div>
  <h3>
    <svg class="icon icon--light icon--help-circle" viewBox="0 0 24 24" aria-hidden="true">
      <use xlink:href="#icon-help" href="#icon-help"></use>
    </svg>
    <span data-test="mono-common-help-title">{{title}}</span>
  </h3>
  <p data-test="mono-common-help-message">{{message}}</p>
  <footer class="flex-container">
    <button data-test="mono-common-help-close-button" class="button button--light margin-right-1" type="button" (click)="closeHelpMessage();  closeButtonExtrasEmit($event);">{{ closeButtonText() }}</button>
    @if (!!secondaryButtonText()) {
      <button class="button button--light" type="button" (click)="closeHelpMessage(); secondaryButtonExtrasEmit()">{{ secondaryButtonText() }}</button>
    }
  </footer>
</aside>