import { Action, createReducer, on } from '@ngrx/store';
import { ErrorStore } from "../interfaces/error-store.interface";
import { CloseGlobalErrorPopup, OpenGlobalErrorPopup } from "./error.actions";


export const initialErrorState: ErrorStore = {
  errorPopupIsOpen: false
}

const reducer = createReducer(
  initialErrorState,
  on(OpenGlobalErrorPopup, (state, {...args}) => Object.assign({}, state, { errorPopupIsOpen: true })),
  on(CloseGlobalErrorPopup, (state, {...args}) => Object.assign({}, state, { errorPopupIsOpen: false })),
)

export function errorReducer(state: ErrorStore | undefined, action: Action) {
  return reducer(state, action);
}
