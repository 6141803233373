<div *ngIf="isOpen">
  <div
    *ngIf="showOverlay"
    (click)="onCloseErrorPopup()"
    class="reveal-overlay reveal-overlay--modal-confirmation"
    aria-hidden="true"
  ></div>
  <mono-common-help-popup
    class="global-error-popup"
    [title]="title"
    [message]="description"
    [open]="isOpen"
    [enableAnimations]="true"
    [shadow]="true"
    (removeHelpMessage)="onCloseErrorPopup()"
></mono-common-help-popup>
</div>
