import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Help } from '../../features/help/abstract/help.abstract';
import { SLIDE_IN_UPWARDS } from "../../animations/animations";


@Component({
  selector: 'mono-common-help-popup',
  templateUrl: './help-popup.component.html',
  animations: [SLIDE_IN_UPWARDS]
})
export class HelpPopupComponent extends Help implements OnInit {

  @Input() open: boolean;
  @Input() shadow?: boolean;
  @Input() pointer?: boolean = false;
  @Input() enableAnimations?: boolean = false;

  @Output() removeHelpMessage: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  public preventFocusOnElementIfPointerPresent(event) {
    if (this.pointer) {
      event.preventDefault();
    }
  }

  public closeHelpPopup() {
    this.destroyWindowResize();
    this.removeHelpMessage.emit(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closeHelpPopup();
  }

}
