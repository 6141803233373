import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'error-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

  @Input() public title?: string;
  @Input() public description?: string

  constructor() { }

  ngOnInit(): void {
  }

}
