import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var gtag: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  analyticsMeasurementId:string = null;

	constructor(@Inject(DOCUMENT) private document: any) {
	}

  public loadGoogleAnalytics(measurementId:string) {
    if(!measurementId){
      return;
    }

    this.analyticsMeasurementId = measurementId;

    this.appendScript(measurementId);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };
    gtag('js', new Date());

    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied'
    });
    gtag('config', measurementId, { cookie_flags: 'SameSite=None;Secure' });
  }

  public sendEvent(eventName: string, eventParameters?: object) {
    if(this.analyticsMeasurementId) {
      gtag('event', eventName, {
        ...eventParameters
      });
    }
  }

  private appendScript(measurementId) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    this.document.body.appendChild(script);
  }
}
