import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { HeaderCommonStore } from "../interfaces/header-common-store";
import { UpdateCommonHeaderStore } from "../store/app.actions";
import { locationSelector } from "../store/app.selectors";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

constructor(private store: Store<HeaderCommonStore>){}

public dispatchUpdateStore(previousRoute: HeaderCommonStore) {
  return this.store.dispatch(UpdateCommonHeaderStore({previousRoute}))
}

public getLocation(): Observable<any>  {
 return this.store.select(locationSelector)
}
}