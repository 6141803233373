import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EmailInputComponent } from './components/email-input/email-input.component';
import { FullNameInputComponent } from './components/full-name-input/full-name-input.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { VerifyCodeInputComponent } from './components/verify-code-input/verify-code-input.component';

@NgModule({
  declarations: [
    EmailInputComponent,
    FullNameInputComponent,
    PasswordInputComponent,
    VerifyCodeInputComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    EmailInputComponent,
    FullNameInputComponent,
    PasswordInputComponent,
    VerifyCodeInputComponent
  ]
})
export class MonoFormsModule { }
