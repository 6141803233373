<main class="grid-container site-container site-container--error-page" id="content">
  <div class="grid-x grid-margin-x">
      <div class="cell">
        <h1 class="secondary" >
          {{ title ? title : "Sorry, we can't give you access to that page (403 error)" }}
        </h1>
        <div
          [innerHTML]="description ? description : 'This is probably a security issue.' | dontSanitizeHtml"></div>
        <ng-content select="[extraContentLower]"></ng-content>
      </div>
  </div>
</main>
