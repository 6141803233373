import { expiredTimeKey } from "../constants/idle-timer.constants";

export class IdleTimer {

  private timeout: number;
  private creationTimeout: number;
  private onTimeout: () => void;
  private intervalTracker;
  private timeoutTracker;
  private eventHandler;

  constructor({ timeoutInSeconds, onTimeout }: { timeoutInSeconds: number, onTimeout: () => void }) {
    this.timeout = timeoutInSeconds;
    this.onTimeout = onTimeout;
    this.creationTimeout = this.checkExpiredTimeOnCreation();

    this.eventHandler = (() => this.updateExpiredTime()).bind(this);
    this.tracker();
    this.startInterval();
  }

  public cleanUp() {
    clearInterval(this.intervalTracker);
    document.removeEventListener("mousedown", this.eventHandler);
    document.removeEventListener("keydown", this.eventHandler);
    sessionStorage.removeItem(expiredTimeKey);
  }

  private checkExpiredTimeOnCreation() {
    if(sessionStorage.getItem(expiredTimeKey)) {
      const expiredTime = parseInt(sessionStorage.getItem(expiredTimeKey) as string, 10);
      const secondsDifference = (expiredTime - Date.now()) / 1000;
      return secondsDifference;
    }
    else {
      return this.timeout;
    }
  }

  private startInterval() {
    this.updateExpiredTime(this.creationTimeout);

    this.intervalTracker = setInterval(() => {
      const expiredTime = parseInt(sessionStorage.getItem(expiredTimeKey) as string, 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  private updateExpiredTime(timeout?: number) {
    const timeoutSecs = timeout ? timeout : this.timeout;
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      sessionStorage.setItem(expiredTimeKey, (Date.now() + timeoutSecs * 1000).toString());
    }, 300);
  }

  private tracker() {
    document.addEventListener("mousedown", this.eventHandler);
    document.addEventListener("keydown", this.eventHandler);
  }
}
