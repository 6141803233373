import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UserClaims } from "@nx/auth";

@Injectable({
  providedIn: 'root'
})
export class SessionUpdatedService {

  public sessionUpdatedAnnouncer: Subject<UserClaims>;

  constructor() {
    this.sessionUpdatedAnnouncer = new Subject<UserClaims>();
  }

  public getSessionUpdatedAnnouncer(): Observable<UserClaims> {
    return this.sessionUpdatedAnnouncer.asObservable();
  }
  public broadcastSessionUpdated(userClaims: UserClaims) {
    this.sessionUpdatedAnnouncer.next(userClaims);
  }
}
