import { animate, style, transition, trigger } from '@angular/animations'

const enterSpeed = '200ms';
const leaveSpeed = '200ms';

export const SLIDE_IN_UPWARDS = trigger('slideInUpwards', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(200px)' }),
    animate(enterSpeed, style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    animate(leaveSpeed, style({ opacity: 0, transform: 'translateY(200px)' }))
  ])
])
