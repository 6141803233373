import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ErrorHeaders } from "../enums/error-headers.enum";
import { ErrorNotificationService } from '../services/error-notification.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class HttpServerErrorInterceptor implements HttpInterceptor {

  constructor(private errorNotificationService: ErrorNotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipErrorHandling = request.headers.has(ErrorHeaders.SkipGenericErrorHandling) || this.errorNotificationService.isErrorHandlingDisabled();

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (skipErrorHandling) {
          return throwError(error);
        } else {
          this.errorNotificationService.showGeneralError();
          return of<HttpEvent<any>>();
        }
      })
    );
  }
}
