<main class="grid-container site-container site-container--error-page" id="content">
  <div class="grid-x grid-margin-x">
      <div class="cell">
        <h1 class="secondary" >
          {{ title ? title : "Sorry, we can't find that page (404 error)" }}
        </h1>
        <div class="lead"
        [innerHTML]="description ? description : 'It looks like you\'ve tried to access a page which isn\'t available.' | dontSanitizeHtml"></div>
        <ng-content select="[extraContentLower]"></ng-content>
      </div>
  </div>
</main>
