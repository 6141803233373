import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'error-global-error-popup',
  templateUrl: './global-error-popup.component.html'
})
export class GlobalErrorPopupComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() isOpen: boolean;
  @Input() showOverlay?: boolean;

  @Output() closePopup = new EventEmitter<void>();

  public onCloseErrorPopup(): void {
    this.closePopup.emit();
  }
}
