import { AbstractControl, ValidationErrors } from '@angular/forms';

export function freeTextValidator(control: AbstractControl): ValidationErrors | null {
  const text = control.value;
  if (!text) {
    return null
  };
  const noIllegalCharactersPattern = /^[^<>{}&\\]+$/;
  if (!noIllegalCharactersPattern.test(text)) {
    return { 'illegalCharacters': true }
  }
  return null;
}
