import { Injectable } from '@angular/core';
import { ErrorHeaders } from "../enums/error-headers.enum";
import { ErrorNotificationService } from '../services/error-notification.service';
import axios from 'axios';


@Injectable({providedIn: 'root'})
export class AxiosServerErrorInterceptor{

  constructor(private errorNotificationService: ErrorNotificationService) {
  }

  intercept() {
    axios.interceptors.response.use(response => {
      return Promise.resolve(response);
    }, error => {
      const skipErrorHandling =
        ErrorHeaders.SkipGenericErrorHandling in error.config.headers || this.errorNotificationService.isErrorHandlingDisabled();
      if (skipErrorHandling) {
        return Promise.reject(error);
      } else {
        this.errorNotificationService.showGeneralError();
        return Promise.resolve();
      }
    });
  }
}

export function ServerErrorInterceptorFactory(serverErrorInterceptor: AxiosServerErrorInterceptor): any {
  return () => serverErrorInterceptor.intercept();
}
