import { Injectable } from '@angular/core';
import { AwsAuthService } from '@nx/auth';

@Injectable({
  providedIn: 'root'
})
export class AppInitialiseService {

  constructor(private AwsAuthService: AwsAuthService) {}

  public async autoUserCheck() {
    try {
      const claims = await this.AwsAuthService.getCurrentUserClaims();
      if(!claims.user.apps.lmw) await this.AwsAuthService.logout();
    }
    catch (e) {}
  }
}