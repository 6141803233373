export interface PreviousRoutesState {
  previousRoutes: {
    [context: string]: string;
  };
}

export const createPreviousRoutes = (previousRoutes: { [context: string]: string }): PreviousRoutesState => ({
    previousRoutes: {
      ...previousRoutes
    }
  })

export const previousRoutesStateCheck = (previousRoutes): previousRoutes is PreviousRoutesState => previousRoutes && "previousRoutes" in previousRoutes